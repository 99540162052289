import React from 'react';

const defaultValue = {
  layoutIndex: 0,
  layoutType: 'fullWidth',
  vertical: null,
  pageRoute: '/',
  path: '/',
  isFirstRail: false,
  isRailLayout: false,
  leadPackageType: null,
  curationAutofill: null,
  adsDisabled: false,
  nonPackageAdsDisabled: false,
  isFirstLayoutWithPackages: false,
  isFluidWidthPage: false,
  pageType: '',
};

export const LayoutContext = React.createContext(defaultValue);
