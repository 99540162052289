module.exports.verticalSiteConfigs = {
  news: {
    iconUrl: 'nbcnews',
    ogSiteName: 'NBC News',
    twitter: 'https://x.com/nbcnews',
    pinterest: 'https://www.pinterest.com/nbcnews/',
    facebook: 'https://www.facebook.com/NBCNews/',
    instagram: 'https://www.instagram.com/nbcnews/',
    youtube: 'https://www.youtube.com/nbcnews',
    snapchat: 'https://www.snapchat.com/p/8bb879c7-45c0-499c-bb3c-7a3d0e229301/2193844248074240',
    tiktok: 'https://www.tiktok.com/@nbcnews?lang=en',
    siteUrl: 'https://www.nbcnews.com',
    twitterContentCreator: 'NBCNews',
    twitterIntentVia: 'NBCNews',
    twitterSite: '@NBCNews',
  },
  today: {
    iconUrl: 'today',
    ogSiteName: 'TODAY.com',
    twitter: 'https://x.com/TODAYshow',
    pinterest: 'https://pinterest.com/todayshow',
    facebook: 'https://www.facebook.com/todayshow',
    instagram: 'https://www.instagram.com/todayshow',
    youtube: 'https://www.youtube.com/today',
    snapchat: 'https://www.snapchat.com/add/todayshow',
    siteUrl: 'https://www.today.com',
    twitterContentCreator: 'TodayShow',
    twitterIntentVia: 'NBCNews',
    twitterSite: '@TODAYshow',
  },
  msnbc: {
    iconUrl: 'msnbc',
    ogSiteName: 'MSNBC.com',
    twitter: 'https://x.com/msnbc',
    pinterest: 'https://www.pinterest.com/msnbc/',
    facebook: 'https://www.facebook.com/msnbc',
    instagram: 'https://www.instagram.com/msnbc/',
    youtube: 'https://www.youtube.com/msnbc',
    snapchat: 'https://www.snapchat.com/add/msnbc',
    tiktok: 'https://www.tiktok.com/@msnbc?lang=en',
    siteUrl: 'https://www.msnbc.com',
    twitterContentCreator: 'MSNBC',
    twitterIntentVia: 'MSNBC',
    twitterSite: '@MSNBC',
  },
  noticias: {
    iconUrl: 'telemundo',
    ogSiteName: 'TELEMUNDO.com',
    twitter: 'https://x.com/TelemundoNews',
    pinterest: '',
    facebook: 'https://www.facebook.com/noticiastelemundo',
    instagram: 'https://www.instagram.com/NoticiasTelemundo',
    youtube: 'https://www.youtube.com/telemundo',
    snapchat: '',
    siteUrl: 'https://www.telemundo.com',
    twitterContentCreator: 'TELEMUNDO',
    twitterIntentVia: 'TelemundoNews',
    twitterSite: '@TELEMUNDO',
  },
  deportes: {
    iconUrl: 'telemundo',
    ogSiteName: 'TELEMUNDO.com',
    twitter: 'https://x.com/TelemundoSports',
    pinterest: '',
    facebook: 'https://www.facebook.com/telemundodeportes',
    instagram: 'https://www.instagram.com/telemundodeportes',
    youtube: 'https://www.youtube.com/telemundo',
    snapchat: '',
    siteUrl: 'https://www.telemundo.com',
    twitterContentCreator: 'TELEMUNDO',
    twitterIntentVia: 'TelemundoSports',
    twitterSite: '@TELEMUNDO',
  },
  localstationdeportes: {
    iconUrl: 'telemundo',
    ogSiteName: 'TELEMUNDO.com',
    twitter: 'https://x.com/TelemundoSports',
    pinterest: '',
    facebook: 'https://www.facebook.com/telemundodeportes',
    instagram: 'https://www.instagram.com/telemundodeportes',
    youtube: 'https://www.youtube.com/telemundo',
    snapchat: '',
    siteUrl: 'https://www.telemundo.com',
    twitterContentCreator: 'TELEMUNDO',
    twitterIntentVia: 'TelemundoSports',
    twitterSite: '@TELEMUNDO',
  },
  telemundo: {
    iconUrl: 'telemundo',
    ogSiteName: 'TELEMUNDO.com',
    twitter: 'https://x.com/Telemundo',
    pinterest: '',
    facebook: 'https://www.facebook.com/telemundo',
    instagram: 'https://www.instagram.com/Telemundo',
    youtube: 'https://www.youtube.com/telemundo',
    snapchat: '',
    siteUrl: 'https://www.telemundo.com',
    twitterContentCreator: 'TELEMUNDO',
    twitterIntentVia: 'Telemundo',
    twitterSite: '@TELEMUNDO',
  },
  select: {
    iconUrl: 'select',
    ogSiteName: 'NBC News',
    twitter: 'https://x.com/shopwithselect',
    pinterest: '',
    facebook: 'https://www.facebook.com/select',
    instagram: 'https://www.instagram.com/shopwithselect',
    youtube: '',
    snapchat: '',
    siteUrl: 'https://www.nbcnews.com/select',
    tiktok: 'https://www.tiktok.com/@shopwithselect',
    twitterContentCreator: 'shopwithselect',
    twitterIntentVia: 'ShopwithSelect',
    twitterSite: '@shopwithselect',
  },
};
