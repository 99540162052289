import PropTypes from 'prop-types';

export default {
  layoutIndex: PropTypes.number,
  layoutType: PropTypes.string,
  vertical: PropTypes.string,
  pageRoute: PropTypes.string,
  pageType: PropTypes.string,
  isFirstRail: PropTypes.bool,
  isRailLayout: PropTypes.bool,
  leadPackageType: PropTypes.string,
  adsDisabled: PropTypes.bool,
  nonPackageAdsDisabled: PropTypes.bool,
  curationAutofill: PropTypes.shape({
    type: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  }),
  isFirstLayoutWithPackages: PropTypes.bool,
  isFluidWidthPage: PropTypes.bool,
  routePath: PropTypes.string,
};
